














































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import SitesServices from "@/services/SitesServices";
import TypeTravauxService from "@/services/TypeTravauxService";

import {Guid} from "guid-typescript";

const OPERATION_SITE_TYPETRAVAUX_COLUMN: string = "operationSiteTypeTravaux";
const OPERATION_CODE_COLUMN: string = "code";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class SitesTypeTravauxTableModal extends Vue {
  /**
   * Defines if the modal is opened or not
   */
  @Prop({default: false}) public show!: boolean;
  /**
   * Property set when the modal is opened
   */
  @Prop({default: undefined}) public props!: any;
  private loaded: boolean = false;
  private sites: any = {};
  private typeTravaux: any = {};
  private selectedSitesTypeTravaux: Array<any> = [];
  private initStateselectedSitesTypeTravaux: Array<any> = [];
  private selectedOperationCoefficientConfigIds: any = {};
  private isSelectAllClicked = false;
  private usedSitesTypeTravaux: any = {};
  private isSelectAllMaintenance: boolean = false;
  private isSelectAllTravauxNeufs: boolean = false;
  private isSelectAllArrets: boolean = false;
  private isNewLine: boolean = false;
  private typeTravauxNames:Map<string,string> = new Map<string, string>();
  private get isDuplicationField() : boolean{
    return this.props?.rowField.isDuplicationField;
  }

  /**
   * Methods
   */
  public validate() {
    let sitesTypeTravaux: Array<any> = [];
    const operationCode = this.props.rowData[OPERATION_CODE_COLUMN];
    const valuesToRemove: Array<any> = this.initStateselectedSitesTypeTravaux.filter(
        (initEl: any) => {
          return (
              this.selectedSitesTypeTravaux.findIndex((el: any) => {
                return (
                    initEl.siteId === el.siteId &&
                    initEl.typeTravauxId === el.typeTravauxId
                );
              }) === -1
          );
        }
    );
    if (this.selectedSitesTypeTravaux.length) {
      this.$store.commit("changeHasBeenModified", true);
    }
    this.selectedSitesTypeTravaux.forEach((el: any) => {
      const siteId = el.siteId;
      const typeTravauxId = el.typeTravauxId;
      const selectedOperationCoefficientConfigId =
          this.selectedOperationCoefficientConfigIds[
              `${siteId}_${typeTravauxId}`
              ] ?? Guid.create().toString();
      const site = this.sites[siteId];
      const typeTravaux = this.typeTravaux[typeTravauxId];
      sitesTypeTravaux.push({
        id: selectedOperationCoefficientConfigId,
        siteId: siteId,
        siteName: site.translation.name,
        typeTravauxId,
        typeTravauxName: typeTravaux.name,
      });

      const usedRowData: any = {
        siteId,
        operationCode,
        typeTravauxId,
        id: selectedOperationCoefficientConfigId,
      };

      this.$store.commit("changeUsedSitesTypeTravaux", usedRowData);
      // }
    });
    const operationUnitValuesToRemove: Array<string> = [];
    valuesToRemove.forEach((el: any) => {
      operationUnitValuesToRemove.push(el.id);
      this.$store.commit("removeTypeTravauxFromchangeUsedSitesTypeTravaux", {
        siteId: el.siteId,
        typeTravauxId: el.typeTravauxId,
        operationCode,
      });
    });
    this.props.rowData[OPERATION_SITE_TYPETRAVAUX_COLUMN] = sitesTypeTravaux;
    this.props.rowData.deletedUnitValues = operationUnitValuesToRemove;
    if(this.isDuplicationField) {
      this.$emit("validate-duplicate", this.props.rowData);
    }else{
      this.$emit("validate", this.props.rowData);
    }
  }

  /**
   * Hooks
   */
  public created() {
    this.loaded = false;
    this.loadData().then(() => {
      this.loaded = true;
    });
  }

  /***
   * watchers
   */
  @Watch("show")
  async onShowChange(value: boolean, oldValue: boolean) {
    if (!value) {
      this.isSelectAllArrets = false;
      this.isSelectAllTravauxNeufs = false;
      this.isSelectAllMaintenance = false;
      this.selectedSitesTypeTravaux = [];
      this.initStateselectedSitesTypeTravaux = [];
      this.selectedOperationCoefficientConfigIds = {};
      return;
    }
    let countSelection: number = 0;
    const sitesTypeTravaux = this.props.rowData[
        OPERATION_SITE_TYPETRAVAUX_COLUMN
        ];
    sitesTypeTravaux.forEach((el: any) => {
      this.selectedOperationCoefficientConfigIds[
          `${el.siteId}_${el.typeTravauxId}`
          ] = el.id;
      this.selectedSitesTypeTravaux.push({
        siteId: el.siteId,
        typeTravauxId: el.typeTravauxId,
      });

      this.initStateselectedSitesTypeTravaux.push({
        id: el.id,
        siteId: el.siteId,
        typeTravauxId: el.typeTravauxId,
      });
      countSelection++;

      // this.selectedSitesTypeTravaux[operationCoefficientConfig.siteId] = [];
    });

    const typeTravaux:Array<any> = Object.values(this.typeTravaux);
    const sitesCount = Object.keys(this.sites).length;
    const totalSelectableElements = sitesCount *
        typeTravaux.length;
    this.isSelectAllClicked =
        totalSelectableElements ===
        countSelection;
    const totalSelectablePerTypeTravaux = sitesCount; //totalSelectableElements/typeTravaux.length
    const maintenanceTypeTravauxId = typeTravaux.find(tt => tt.key === 'maintenance').id;
    const travauxNeufsTypeTravauxId = typeTravaux.find(tt => tt.key === 'travauxNeufs').id;
    const arretsTypeTravauxId = typeTravaux.find(tt => tt.key === 'arrets').id;
    this.isSelectAllMaintenance = this.selectedSitesTypeTravaux.filter(el => el.typeTravauxId === maintenanceTypeTravauxId).length === totalSelectablePerTypeTravaux
    this.isSelectAllTravauxNeufs = this.selectedSitesTypeTravaux.filter(el => el.typeTravauxId === travauxNeufsTypeTravauxId).length === totalSelectablePerTypeTravaux
    this.isSelectAllArrets = this.selectedSitesTypeTravaux.filter(el => el.typeTravauxId === arretsTypeTravauxId).length === totalSelectablePerTypeTravaux
    this.usedSitesTypeTravaux = this.$store.state.usedSitesTypeTravaux;

    // this.$forceUpdate();
  }

  @Watch("selectedSitesTypeTravaux")
  onSelectedSitesTypeTravauxChange(value: Array<any>, oldValue: Array<any>) {
  }

  private selectAll() {
    if (this.isSelectAllClicked) {
      this.selectedSitesTypeTravaux = [];
      this.isSelectAllClicked = false;
      this.isSelectAllArrets = false;
      this.isSelectAllTravauxNeufs = false;
      this.isSelectAllMaintenance = false;
    } else {
      const operationCode = this.props.rowData[OPERATION_CODE_COLUMN];
      const operationData = this.usedSitesTypeTravaux[operationCode];
      for (let s in this.sites) {
        const siteId = this.sites[s].site.id;
        // this.selectedSitesTypeTravaux[site.site.id] = [];
        for (let t in this.typeTravaux) {
          const typeTravauxId = this.typeTravaux[t].id;
          const selectedOperationCoefficientConfigId = this
              .selectedOperationCoefficientConfigIds[
              `${siteId}_${typeTravauxId}`
              ];
          if (
              !operationData ||
              !operationData[`${siteId}_${typeTravauxId}`] ||
              operationData[`${siteId}_${typeTravauxId}`] ===
              selectedOperationCoefficientConfigId
          )
            this.selectedSitesTypeTravaux.push({
              siteId,
              typeTravauxId,
            });
        }
      }
      this.isSelectAllClicked = true;
      this.isSelectAllArrets = true;
      this.isSelectAllTravauxNeufs = true;
      this.isSelectAllMaintenance = true;
    }
    this.$forceUpdate();
  }

  private isOptionDisabled(siteId: string, typeTravauxId: string) {
    const selectedOperationCoefficientConfigId = this
        .selectedOperationCoefficientConfigIds[`${siteId}_${typeTravauxId}`];
    if (!this.props.rowData) return true;
    // let state: boolean = this.usedSitesTypeTravaux[siteId] && this.usedSitesTypeTravaux[siteId].operationCoefficientConfigId !== selectedOperationCoefficientConfigId
    // && this.usedSitesTypeTravaux[siteId].typeTravaux[typeTravauxId];
    const operationCode = this.props.rowData[OPERATION_CODE_COLUMN];
    const operationData = this.usedSitesTypeTravaux[operationCode];
    let isDisabled: boolean =
        operationData &&
        operationData[`${siteId}_${typeTravauxId}`] &&
        operationData[`${siteId}_${typeTravauxId}`] !==
        selectedOperationCoefficientConfigId;

    return isDisabled;
  }

  private async loadData() {
    const sites = (await SitesServices.getEnabledSites(this.$i18n.locale)).data;
    sites.forEach((el: any) => {
      this.sites[el.site.id] = el;
    });
    // this.sites = (await SitesServices.getSites()).data;
    const typeTravaux = (
        await TypeTravauxService.listTypeTravaux(this.$i18n.locale)
    ).data;
    typeTravaux.forEach((el: any) => {
      this.typeTravaux[el.id] = el;
      this.typeTravauxNames.set(el.key,el.name);
    });
  }
  private onSelectAllMaintenanceChange(value) {
    const maintenanceKey = 'maintenance'
    if (!value) {
      this.isSelectAllClicked = false;
      this.deSelectAllPerTypeTravauxKey(maintenanceKey);
      return;
    }
    this.selectAllPerTypeTravauxKey(maintenanceKey);


  }
  private onSelectAllTravauxNeufsChange(value){
    const TravauxNeufsKey = 'travauxNeufs';
    if (!value) {
      this.isSelectAllClicked = false;
      this.deSelectAllPerTypeTravauxKey(TravauxNeufsKey);
      return;
    }
    this.selectAllPerTypeTravauxKey(TravauxNeufsKey);
  }
  private onSelectAllArretsChange(value){
    const arretsKey = 'arrets'
    if (!value) {
      this.isSelectAllClicked = false;
      this.deSelectAllPerTypeTravauxKey(arretsKey);
      return;
    }
    this.selectAllPerTypeTravauxKey(arretsKey);

  }
  private selectAllPerTypeTravauxKey(typeTravauxKey){
    const operationCode = this.props.rowData[OPERATION_CODE_COLUMN];
    const operationData = this.usedSitesTypeTravaux[operationCode];
    const typeTravaux:Array<any> = Object.values(this.typeTravaux)
    for (let s in this.sites) {
      const siteId = this.sites[s].site.id;
      const typeTravauxId = typeTravaux.find(tt => tt.key === typeTravauxKey).id;
      const selectedOperationCoefficientConfigId =
          this.selectedOperationCoefficientConfigIds[`${siteId}_${typeTravauxId}`];
      if (
          !operationData ||
          !operationData[`${siteId}_${typeTravauxId}`] ||
          operationData[`${siteId}_${typeTravauxId}`] ===
          selectedOperationCoefficientConfigId
      )
        this.selectedSitesTypeTravaux.push({
          siteId,
          typeTravauxId,
        });

    }
    const sitesCount = Object.keys(this.sites).length;
    const totalSelectableElements = sitesCount *
        typeTravaux.length;
    this.isSelectAllClicked =
        totalSelectableElements ===
        this.selectedSitesTypeTravaux.length;
  }
  private deSelectAllPerTypeTravauxKey(typeTravauxKey){
    const typeTravaux:Array<any> = Object.values(this.typeTravaux)

    const typeTravauxId = typeTravaux.find(tt => tt.key === typeTravauxKey).id;
    this.selectedSitesTypeTravaux = this.selectedSitesTypeTravaux.filter(el => el.typeTravauxId !== typeTravauxId);
  }
}
